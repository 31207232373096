import { Components } from '@mui/material/styles';

export const MuiModal: Components['MuiModal'] = {
  styleOverrides: {
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
};
