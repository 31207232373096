import { Components } from '@mui/material/styles';

export const MuiToolbar: Components['MuiToolbar'] = {
  styleOverrides: {
    root: {
      height: 64,
      gap: 16,
    },
  },
};
