import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiPopover: Components['MuiPopover'] = {
  styleOverrides: {
    paper: {
      backgroundColor: colors.background.secondary,
    },
  },
};
