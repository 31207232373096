import Keycloak from 'keycloak-js';
import { ConnectionSettings } from 'entities';
import { defined } from 'shared/lib/checks';

const tokenMinValidity = 30;

export type UserProfile = {
  /**
   * Идентификатор.
   */
  subject: string;

  /**
   * Логин.
   */
  username: string;

  /**
   * Отображаемое имя.
   */
  displayName: string;
};

class Auth {
  private keycloak: Keycloak | undefined;

  /**
   * Профиль пользователя.
   * Не завязано на конкретную реализацию (keycloak, idsrv).
   */
  public profile: UserProfile | undefined;

  /**
   * Обновить и получить текущий токен.
   */
  public async getToken(): Promise<string> {
    if (this.keycloak) {
      await this.keycloak.updateToken(tokenMinValidity);

      const token = this.keycloak.token;

      if (token) {
        return token;
      }
    }

    throw new Error('Token error.');
  }

  /**
   * Получить текущий токен без его обновления, если истекает срок годности.
   * Устарело, используется только в devextreme.
   */
  public getCurrentToken(): string {
    const token = this.keycloak?.token;

    if (!token) {
      throw new Error('Token error.');
    }

    return token;
  }

  public async init(settings: ConnectionSettings): Promise<void> {
    this.keycloak = new Keycloak({
      url: settings.oidUrl,
      realm: settings.oidRealm,
      clientId: settings.oidClientId,
    });

    this.keycloak.onTokenExpired = () => {
      void this.keycloak?.updateToken(tokenMinValidity);
    };

    await this.keycloak.init({
      onLoad: 'login-required',
    });

    if (!this.keycloak.authenticated) {
      await this.keycloak.login();
    }

    const profile = await this.keycloak.loadUserProfile();

    this.profile = {
      subject: defined(profile.id),
      username: defined(profile.username),
      displayName:
        profile.firstName || profile.lastName
          ? `${profile.firstName ?? ''} ${profile.lastName ?? ''}`.trim()
          : defined(profile.username),
    };
  }

  public async login(): Promise<void> {
    await this.keycloak?.login();
  }

  public async logout(): Promise<void> {
    await this.keycloak?.logout();
  }

  public async accountManagement(): Promise<void> {
    await this.keycloak?.accountManagement();
  }
}

export default new Auth();
