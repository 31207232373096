import { ValueRequest } from 'entities/dataValue';
import { TimeStepValueResponse } from 'entities/dataValue/model/response';
import api from 'shared/api';

export const getTimeDiagramValuesV2 = async (
  contextId: number,
  request: ValueRequest
): Promise<TimeStepValueResponse> => {
  return api.post<TimeStepValueResponse>(`/contexts/${contextId}/dashboards-v2/data-by-intervals-v2`, request);
};
