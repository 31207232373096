import { PickersLocaleText } from '@mui/x-date-pickers-pro';

const ruRU: Partial<PickersLocaleText<unknown>> = {
  // Calendar toolbar
  // Calendar navigation
  previousMonth: 'Предыдущий месяц',
  nextMonth: 'Следующий месяц',

  // View navigation
  openPreviousView: 'открыть предыдущий вид',
  openNextView: 'открыть следующий вид',

  // DateRange placeholders
  start: 'Начало',
  end: 'Завершение',

  // Action bar
  cancelButtonLabel: 'Отмена',
  clearButtonLabel: 'Очистить',
  okButtonLabel: 'OK',
  todayButtonLabel: 'Сегодня',

  // Clock labels
  clockLabelText: (view, time, adapter) =>
    `Выбор ${view}. ${time === null ? 'Время не выбрано' : `Текущее время ${adapter.format(time, 'fullTime')}`}`,
  hoursClockNumberText: (hours) => `${hours} часов`,
  minutesClockNumberText: (minutes) => `${minutes} минут`,
  secondsClockNumberText: (seconds) => `${seconds} секунд`,

  // Open picker labels
  openDatePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Изменить дату, текущая дата ${utils.format(utils.date(rawValue), 'fullDate')}`
      : 'Изменить дату',
  openTimePickerDialogue: (rawValue, utils) =>
    rawValue && utils.isValid(utils.date(rawValue))
      ? `Изменить время, текущее время ${utils.format(utils.date(rawValue), 'fullTime')}`
      : 'Изменить время',

  // Table labels
  timeTableLabel: 'выбор времени',
  dateTableLabel: 'выбор даты',
};

export default ruRU;
