import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiDialogContent: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      padding: 0,
      backgroundColor: colors.background.secondary,
    },
  },
};
