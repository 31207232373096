import Error from '../Error';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

export type ErrorBoundaryProps = {
  fallbackText?: string;
  errorText?: string;
};

const ErrorBoundary: React.FC<React.PropsWithChildren<ErrorBoundaryProps>> = ({
  children,
  fallbackText,
  errorText = 'Error',
}) => {
  return <ReactErrorBoundary fallback={<Error text={fallbackText ?? errorText} />}>{children}</ReactErrorBoundary>;
};

export default ErrorBoundary;
