/**
 * @deprecated
 * Adds duration to date
 * @param {Date} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date
 */
export function addDurationToDate(dateTime: Date, duration: number): Date;
/**
 * @deprecated
 * Adds duration to date
 * @param {String} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date
 */
export function addDurationToDate(dateTime: string | Date, duration: number): Date {
  const date = new Date(dateTime);
  date.setDate(date.getDate() + duration);
  return date;
}
