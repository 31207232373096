import { colors, fonts } from 'shared/theme/assets';
// eslint-disable-next-line no-restricted-imports
import noaccess from './no-access.svg';
import './styles.scss';
import Button from '@mui/material/Button';
import { ConnectionState } from 'entities';

export type StartupScreenProps = {
  connectionState: ConnectionState;
  text?: string;
};

/**
 * Компонент стартового экрана.
 * Должен быть максимально лёгким и не импортировать в себя тяжелые библиотеки, например, mui.
 */
const StartupScreen: React.FC<StartupScreenProps> = ({ connectionState, text }) => {
  return (
    <div className="startup-screen" style={{ backgroundColor: colors.local.black }}>
      {/* Описание */}
      {connectionState === 'Error' && (
        <div
          className="text"
          style={{
            ...fonts.caption2,
          }}
        >
          <img src={noaccess} alt="no-access" />
          <h1 style={{ color: colors.text.white, fontWeight: 400, fontSize: '36px' }}>Отсутствует доступ</h1>
          <span style={{ color: colors.text.secondary, fontWeight: 400, fontSize: '16px' }}>
            Обратитесь к администратору системы
          </span>
          <br />
          <Button variant="contained" href="/" sx={{ marginTop: '24px' }}>
            На главную
          </Button>
        </div>
      )}
      {connectionState != 'Error' && (
        <div
          className="text"
          style={{
            ...fonts.caption2,
            color: colors.text.secondary,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default StartupScreen;
