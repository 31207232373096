import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiAppBar: Components['MuiAppBar'] = {
  styleOverrides: {
    root: {
      color: colors.text.primary,
      background: colors.background.primary,
      boxShadow: `inset 0px -1px 0px ${colors.border.disabled}`,
      border: `1px solid ${colors.border.disabled}`,
    },
  },
};
