import { makeAutoObservable } from 'mobx';

class PyramidStore {
  public pyramids: Record<string, number> = {};
  constructor() {
    makeAutoObservable(this);
  }

  public updatePyramid(id: string) {
    if (this.pyramids[id]) {
      this.pyramids[id] += 1;
      return;
    }

    this.pyramids[id] = 1;
  }
}
export default new PyramidStore();
