import { TimeStepBaseSeries } from 'entities/chart/model';
import { TimeStepValueResponse } from 'entities/dataValue/model/response';
import { CategoryDiagramSettings, ChartType } from 'entities/panel';
import { getSeriesValue } from './getSeriesValue';
import { DiagramPagination } from 'entities/chart/diagram-store';

export const parseTimeSeries = (
  response: TimeStepValueResponse,
  config: CategoryDiagramSettings,
  pagination?: DiagramPagination
): TimeStepBaseSeries[] => {
  const result: TimeStepBaseSeries[] = [];
  const type = config.visuals.type as ChartType;
  const limit = pagination?.limit ?? 10;
  const page = pagination?.page ?? 1;
  const start = (page - 1) * limit;
  const end = start + limit;

  response.series.slice(start, end).forEach((item) => {
    const name = item.nodeTitle;
    result.push({
      name,
      meta: {
        groupId: item.parentNodeId || item.nodeId,
        nodeId: item.nodeId,
      },
      data: item.values.map((value, index) => ({
        x: response.periods[index]?.start ?? new Date().toISOString(),
        y: getSeriesValue(value, config.visuals.dataLabels?.value?.fraction ?? 0),
      })),
    });
  });

  if (type == 'pie' || type == 'donut') {
    return result;
  }

  return result;
};
