import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiDialogTitle: Components['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      color: colors.text.primary,
      backgroundColor: colors.background.secondary,
      boxShadow: `inset 0px -1px 0px ${colors.border.primary}`,
      border: 'none',
      height: 64,
      gap: 16,
      padding: 0,
    },
  },
};
