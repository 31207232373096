import { addDurationToDate } from './addDurationToDate';

/**
 * @deprecated
 * Shifts end period date from internal date (next day 00:00:00) to user-friendly date
 * @param {Date} internalEndDate Internal end period date
 * @returns {Date} User-friendly end period date
 */
export function endPeriodInternalToDisplayed(internalEndDate: Date): Date {
  return addDurationToDate(internalEndDate, -1);
}
