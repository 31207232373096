import { OffsetApplication, RangeSliding } from 'shared/types';
import { isOffsetLessThanPeriod } from './isOffsetLessThenPeriod';
import { defined } from '../checks';

/**
 * Функция проверки нужно ли и в какой момент применять смещение периода.
 * Варианты: Не нужно, сместить опорную дату, сместить даты начала и окончания результирующего периода.
 * @param {RangeSliding} range Объект периода.
 * @param {boolean} calendarBinding Привязка к календарю.
 * @returns {OffsetApplication} Когда требуется смещение.
 */
export function shouldApplyOffset(range: RangeSliding, calendarBinding: boolean): OffsetApplication {
  if (range.haveOffset) {
    if (calendarBinding) {
      return isOffsetLessThanPeriod(range.range, defined(range.offset)) ? 'End' : 'Start';
    } else {
      return 'Start';
    }
  } else {
    return 'NoOffset';
  }
}
