import { WeekDays } from 'shared/types';

/**
 * Расчёт дня окончания недели в числах от 1 до 7 на основе дня начала недели.
 * @param {WeekDays} startDay День начала недели.
 * @returns {Number} День окончания недели.
 */
export function getWeekEndDay(startDay: WeekDays) {
  switch (startDay) {
    case 'Monday': {
      return 7;
    }
    case 'Tuesday': {
      return 1;
    }
    case 'Wednesday': {
      return 2;
    }
    case 'Thursday': {
      return 3;
    }
    case 'Friday': {
      return 4;
    }
    case 'Saturday': {
      return 5;
    }
    case 'Sunday': {
      return 6;
    }
  }
}
