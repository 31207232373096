import { ClassifierNode, ValueRequest, ValueRequestIndicator } from 'entities';
import { CardSettings } from '../model';
import { validateConfig } from '.';
import { getDatesFromConfig, getPeriodAsOffset } from 'shared/lib/period';
import { defined } from 'shared/lib/checks';
import { DYNAMICS_KEY, FACT_KEY, TREND_KEY } from '../config/card/variables';
import { DateTime } from 'luxon';

export const parseConfig = (
  config: CardSettings,
  groupByRootId?: string,
  currentDate = DateTime.now(),
  currentClassifierId?: string,
  currentRootClassifierId?: string
): ValueRequest | undefined => {
  if (!validateConfig(config)) {
    return;
  }

  let byIndicators: ValueRequestIndicator[] = [];

  if ((config.filters?.splitterValues?.length ?? 0) > 0) {
    byIndicators = defined(config.filters?.splitterValues).map((value) => {
      const period = getDatesFromConfig(
        defined(value.period),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.globalOffset && config.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.globalOffset }
          : undefined
      );

      return {
        key: value.key,
        indicatorId: defined(config.filters).indicatorId,
        period: {
          start: period.start,
          end: period.end,
        },
        nodeIds: [value.node],
        forPeriodAggregation:
          config.filters?.aggregationType?.toString() === 'unset' ? undefined : config.filters?.aggregationType,
        forPeriodCalculation: value.calculationType,
        withChildrenSum: config.classifiers.childrenSumEnabled,
        nodesAggregation: config.classifiers.aggregationType,
      } as ValueRequestIndicator;
    });

    if (config.indicator.show) {
      const period = getDatesFromConfig(
        defined(config.filters?.trendPeriod),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.globalOffset && config.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.globalOffset }
          : undefined
      );

      const byIndicatorsFact = byIndicators.find((value) => value.key === FACT_KEY);

      if (byIndicatorsFact) {
        byIndicators.push({
          ...byIndicatorsFact,
          key: TREND_KEY,
          period: {
            start: period.start,
            end: period.end,
          },
        } as ValueRequestIndicator);
      }
    }

    if (config.dynamics.show) {
      const period = getPeriodAsOffset(
        getDatesFromConfig(
          defined(config.filters?.mainPeriod),
          currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
          undefined,
          config.globalOffset && config.globalOffset > 0
            ? { hasOffset: true, offsetDays: config.globalOffset }
            : undefined
        )
      );

      const byIndicatorsFact = byIndicators.find((value) => value.key === FACT_KEY);

      if (byIndicatorsFact) {
        byIndicators.push({
          ...byIndicatorsFact,
          key: DYNAMICS_KEY,
          period: {
            start: period.start,
            end: period.end,
          },
        } as ValueRequestIndicator);
      }
    }
  } else {
    const period = getDatesFromConfig(
      defined(config.filters?.mainPeriod),
      currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
      undefined,
      config.globalOffset && config.globalOffset > 0 ? { hasOffset: true, offsetDays: config.globalOffset } : undefined
    );

    byIndicators = [
      {
        indicatorId: defined(config.filters).indicatorId,
        period: {
          start: period.start,
          end: period.end,
        },
        nodeIds: undefined,
        forPeriodAggregation:
          config.filters?.aggregationType?.toString() === 'unset' ? undefined : config.filters?.aggregationType,
        forPeriodCalculation: config.filters?.calculationType,
        withChildrenSum: config.classifiers.childrenSumEnabled,
        nodesAggregation: config.classifiers.aggregationType,
      } as ValueRequestIndicator,
    ];

    if (config.indicator.show) {
      const period = getDatesFromConfig(
        defined(config.filters?.trendPeriod),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.globalOffset && config.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.globalOffset }
          : undefined
      );

      const byIndicatorsValue = byIndicators[0];

      if (byIndicatorsValue) {
        byIndicators.push({
          ...byIndicatorsValue,
          key: TREND_KEY,
          period: {
            start: period.start,
            end: period.end,
          },
        } as ValueRequestIndicator);
      }
    }

    if (config.dynamics.show) {
      const period = getPeriodAsOffset(
        getDatesFromConfig(
          defined(config.filters?.mainPeriod),
          currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
          undefined,
          config.globalOffset && config.globalOffset > 0
            ? { hasOffset: true, offsetDays: config.globalOffset }
            : undefined
        )
      );

      const byIndicatorsValue = byIndicators[0];

      if (byIndicatorsValue) {
        byIndicators.push({
          ...byIndicatorsValue,
          key: DYNAMICS_KEY,
          period: {
            start: period.start,
            end: period.end,
          },
        } as ValueRequestIndicator);
      }
    }
  }

  const nodeIds =
    currentClassifierId && currentRootClassifierId
      ? config.filters?.nodes.map((node) => {
          if (node.rootId === currentRootClassifierId) {
            return {
              ...node,
              nodeId: currentClassifierId,
            } as ClassifierNode;
          } else {
            return node;
          }
        })
      : config.filters?.nodes;

  const request: ValueRequest = {
    byIndicators,
    nodeIds,
    forPeriodAggregation:
      config.filters?.aggregationType?.toString() === 'unset' ? undefined : config.filters?.aggregationType,
    forPeriodCalculation: config.filters?.calculationType,
    withChildrenSum: config.classifiers.childrenSumEnabled,
    nodesAggregation: config.classifiers.aggregationType,
    fillClassifiers: false,
    fillIndicators: false,
    groupByRootId,
  };

  return request;
};
