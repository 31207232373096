import { Components } from '@mui/material/styles';
import { fonts } from '../assets';

export const MuiTypography: Components['MuiTypography'] = {
  styleOverrides: {
    body1: {
      ...fonts.body1,
    },
    body2: {
      ...fonts.body2,
    },
  },
};
