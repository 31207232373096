import { v4 as uuid, NIL, validate } from 'uuid';

export class Guid {
  public static EMPTY = NIL;

  public static isGuid(guid: Guid | string): boolean {
    const value: string = guid.toString();
    return !!guid && (guid instanceof Guid || validate(value));
  }

  public static create(): Guid {
    return new Guid(uuid());
  }

  public static createEmpty(): Guid {
    return new Guid(NIL);
  }

  public static parse(guid: string): Guid {
    return new Guid(guid);
  }

  public static raw(): string {
    return uuid();
  }

  private readonly value: string = NIL;

  private constructor(guid: string) {
    if (!guid) {
      throw new TypeError('Invalid argument; `value` has no value.');
    }

    if (guid && Guid.isGuid(guid)) {
      this.value = guid;
    }
  }

  public equals(other: Guid): boolean {
    return Guid.isGuid(other) && this.value === other.toString();
  }

  public isEmpty(): boolean {
    return this.value === Guid.EMPTY;
  }

  public toString(): string {
    return this.value;
  }

  public toJSON(): string {
    return this.value;
  }
}
