import { Layout } from '../model';

export const defaults: Layout = {
  rowHeight: 25,
  colCount: 12,
  margin: {
    x: 10,
    y: 10,
  },
  isMobile: false,
};
