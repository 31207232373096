import { DateTime } from 'luxon';
import { DateRange } from 'shared/types';
import { getDatesFromConfig } from './getDatesFromConfig';

export const isDateFitsPeriod = (period: DateRange, date: DateTime, currentDate = DateTime.now().toJSDate()) => {
  const dates = getDatesFromConfig(period, currentDate);

  return (
    DateTime.fromJSDate(new Date(dates.start)).toMillis() - date.toMillis() <= 0 &&
    DateTime.fromJSDate(new Date(dates.end)).toMillis() - date.toMillis() >= 0
  );
};
