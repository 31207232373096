import { ApexOptions } from 'apexcharts';
import { DiagramPagination } from 'entities/chart/diagram-store';
import { BaseSeries, BaseSeriesData, EventOptions, TimeStepBaseSeries } from 'entities/chart/model';
import { Classifier } from 'entities/classifier';
import contextStore from 'entities/context/contextStore';
import { TimeStepValueResponse, ValueResponse } from 'entities/dataValue/model/response';
import { CategoryDiagramSettings } from 'entities/panel';
import panelsStore from 'entities/panel/panelsStore';
import { DateTime } from 'luxon';
import { toJS } from 'mobx';
import { Props } from 'react-apexcharts';
import { defined } from 'shared/lib/checks';
import { getSeriesAndResponse } from './getSeries';
import { isPieChart } from './isPieChart';
import { chartTypeMapper, parseOptions } from './parseOptions';
import { parseRequest } from './parseRequest';
import { loadClassifiersById } from 'entities/classifier/api';

const isTimeSeriesResponse = (
  response: ValueResponse | TimeStepValueResponse,
  config: CategoryDiagramSettings
): response is TimeStepValueResponse => {
  return Boolean(config.filters?.byPeriod.enabled);
};

export const generateConfig = async (
  blockId: string,
  config: CategoryDiagramSettings,
  currentDate = DateTime.now(),
  currentClassifierId?: string,
  currentRootClassifier?: Classifier,
  parentNode?: HTMLDivElement,
  pagination?: DiagramPagination
) => {
  const updatedConfig = () => {
    const _config = JSON.parse(JSON.stringify(config)) as CategoryDiagramSettings;
    if (!_config.filters) {
      return _config;
    }
    return _config;
  };

  const request = parseRequest(updatedConfig(), currentDate, currentClassifierId);

  if (!request) {
    return;
  }

  const { series, response, seriesCount } = await getSeriesAndResponse(config, request, pagination);

  const updateSeries = async (options: EventOptions) => {
    const isTimeStep = !!config.filters?.byPeriod.enabled;
    if (!config.classifiers.drillDown.enabled) {
      return;
    }

    const drillDown = config.classifiers.drillDown;
    const serie = series[options.seriesIndex];

    const data = (serie as BaseSeries).data[options.dataPointIndex] as BaseSeriesData;
    const history = toJS(config.temporary.history) || [];
    const timeStepMeta = (serie as TimeStepBaseSeries).meta;

    const rootId: string = (isTimeStep ? timeStepMeta?.groupId : currentRootClassifier?.innerId) || '';
    const nodeId: string = (isTimeStep ? timeStepMeta?.nodeId : data.meta?.groupId) || rootId;

    if (!history.length && !config.temporary.groupId) {
      await panelsStore.updateBlockSettings(defined(contextStore.currentContextId), blockId, {
        ...config,
        temporary: {
          ...config.temporary,
          groupId: rootId,
        },
      });
      return;
    }

    let currentLevelDrillDown = toJS(config.temporary.currentLevelDrillDown) || 0;
    const currentOrderRootId = drillDown.drillDownOrder?.[currentLevelDrillDown] || rootId;
    const drillDownOrderLength = drillDown.drillDownOrder?.length || 0;
    const lastHistory = history.at(-1);

    if (currentLevelDrillDown < drillDownOrderLength || !history.length) {
      if (rootId === currentOrderRootId) {
        if (lastHistory) {
          lastHistory.nodes.push(nodeId);
        } else {
          history.push({ rootId, nodes: [nodeId] });
        }
      } else {
        const lastClickedRoot = history.find((item) => item.rootId === currentOrderRootId);
        history.push({
          rootId: currentOrderRootId,
          nodes: [lastClickedRoot?.lastClickedNode || currentOrderRootId],
        });

        currentClassifierId = currentOrderRootId;
      }
      if (lastHistory) {
        lastHistory.lastClickedNode = nodeId;
      }
      currentLevelDrillDown += 1;
    } else {
      const clickedClassifier = await loadClassifiersById(contextStore.currentContextId || 0, [nodeId]);
      const hasChildren = !!clickedClassifier[0]?.childrenCount;
      if (lastHistory && hasChildren) {
        lastHistory.nodes.push(nodeId);
      }
    }

    await panelsStore.updateBlockSettings(defined(contextStore.currentContextId), blockId, {
      ...config,
      temporary: {
        ...config.temporary,
        history,
        currentLevelDrillDown,
        currentRootClassifier: currentOrderRootId,
      },
    });
  };

  const onlyUnique = (value: string, index: number, array: string[]) => {
    return array.indexOf(value) === index;
  };

  const getNodeTitles = () => {
    if (isTimeSeriesResponse(response, config)) {
      return [];
    }

    // if chart has many columns
    const groups = response.byGroups;
    if (groups.length > 1 || groups[0]?.nodeId) {
      return groups.map((group) => defined(group.nodeTitle)).filter(onlyUnique);
    }

    // if chart has only one column and selected classifier from folder
    if (currentClassifierId) {
      return [response.classifiers?.find((c) => c.innerId === currentClassifierId)?.title ?? ''];
    }

    const nodeIdSet = new Set(response.nodeIds?.map((node) => node.nodeId));
    const currentClassifier = response.classifiers?.filter((classifier) => !nodeIdSet.has(classifier.innerId))[0];

    // if chart has only one column
    return [currentClassifier?.title ?? ''] as [string];
  };

  const options = parseOptions(config, getNodeTitles(), updateSeries, parentNode);

  if (isPieChart(config)) {
    const res = response as ValueResponse;
    const ids = res.byGroups[0]?.byIndicators.map((indicator) => indicator.nodeIds?.[0]?.nodeId) || [];

    const labels: string[] = [];
    ids.forEach((id) => {
      if (id) {
        const classifier = res.classifiers?.find((c) => c.innerId === id);
        if (classifier) {
          labels.push(classifier.title);
        }
      }
    });
    options.labels = labels;
  }

  const result: Props = {
    type: chartTypeMapper.get(config.visuals.type)?.type,
    series: series as ApexOptions['series'],
    options: options,
    gridLineWidth: config.visuals.gridLineType?.width ?? '2',
  };

  return { config: result, seriesCount };
};
