import { Components } from '@mui/material/styles';

export const MuiDialogActions: Components['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      height: 80,
      padding: 16,
    },
  },
};
