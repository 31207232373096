import { Classifier } from 'entities/classifier';
import { loadClassifiersById } from 'entities/classifier/api';
import contextStore from 'entities/context/contextStore';
import { CategoryDiagramSettings } from 'entities/panel';
import { DateTime } from 'luxon';
import { makeAutoObservable } from 'mobx';
import { Props } from 'react-apexcharts';
import { defined } from 'shared/lib/checks';
import { generateConfig } from './lib/diagram/generateConfig';

export type DiagramPagination = {
  page: number;
  total: number;
  limit: number;
};

class DiagramStore {
  private limit = 10;

  public diagrams: Record<
    string,
    {
      config?: Props;
      isLoading: boolean;
      classifier?: Classifier;
      currentDate?: DateTime;
      currentClassifierId?: string;
      pagination?: DiagramPagination;
    }
  > = {};
  constructor() {
    makeAutoObservable(this);
  }

  changePage(blockId: string, page: number) {
    const diagram = defined(this.diagrams[blockId]);
    if (!diagram.pagination) {
      return null;
    }
    diagram.pagination.page = page;
  }

  public async loadDiagram(
    blockId: string,
    {
      filters,
      currentDate,
      currentClassifierId,
      parentNode,
    }: {
      filters: CategoryDiagramSettings;
      currentDate?: DateTime;
      currentClassifierId?: string;
      isFirstRender?: boolean;
      parentNode?: HTMLDivElement;
    }
  ) {
    if (!(blockId in this.diagrams)) {
      this.diagrams[blockId] = {
        config: undefined,
        isLoading: false,
      };
    }

    const diagram = defined(this.diagrams[blockId]);

    if (!filters.temporary.currentRootClassifier) {
      return;
    }
    diagram.isLoading = true;
    try {
      diagram.classifier = (
        await loadClassifiersById(defined(contextStore.currentContextId), [filters.temporary.currentRootClassifier])
      )[0];
      const res = await generateConfig(
        blockId,
        filters,
        currentDate || diagram.currentDate,
        currentClassifierId || diagram.currentClassifierId,
        diagram.classifier,
        parentNode,
        diagram.pagination
      );
      diagram.config = res?.config;
      if (res?.seriesCount && res.seriesCount > this.limit) {
        diagram.pagination = { page: diagram.pagination?.page || 1, total: res.seriesCount, limit: this.limit };
      }
    } finally {
      diagram.isLoading = false;
    }
  }
}

export default new DiagramStore();
