import { getTimeDiagramValuesV2 } from 'entities/chart/api';
import { BaseSeries, TimeStepBaseSeries } from 'entities/chart/model';
import contextStore from 'entities/context/contextStore';
import { getValues } from 'entities/dataValue/api';
import { CategoryDiagramSettings } from 'entities/panel';
import { defined } from 'shared/lib/checks';
import { parseSeries } from './parseSeries';
import { parseTimeSeries } from './parseTimeSeries';
import { ValueRequest, TimeStepValueRequest } from 'entities/dataValue';
import { TimeStepValueResponse, ValueResponse } from 'entities/dataValue/model/response';
import { DiagramPagination } from 'entities/chart/diagram-store';

export const getSeriesAndResponse = async (
  config: CategoryDiagramSettings,
  request: ValueRequest | TimeStepValueRequest,
  pagination?: DiagramPagination
): Promise<{
  series: TimeStepBaseSeries[] | BaseSeries[] | number[];
  response: TimeStepValueResponse | ValueResponse;
  seriesCount?: number;
}> => {
  if (config.filters?.byPeriod.enabled) {
    const response = await getTimeDiagramValuesV2(defined(contextStore.currentContextId), request);
    const series = parseTimeSeries(response, config, pagination);
    return { series, response, seriesCount: response.series.length };
  }

  const response = await getValues(defined(contextStore.currentContextId), request);
  const series = parseSeries(response, config);
  return { series, response };
};
