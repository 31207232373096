import { ApexOptions } from 'apexcharts';
import ru from 'apexcharts/dist/locales/ru.json';
import { EventOptions } from 'entities/chart/model';
import i18n from 'entities/localization/i18n';
import { CategoryDiagramSettings, ChartTypeSetting, ChartTypeSettings } from 'entities/panel';
import { defined } from 'shared/lib/checks';
import { PaletteThemes, nextPaletteTheme } from './themes';

export const chartTypeMapper = new Map<ChartTypeSetting, ChartTypeSettings>([
  ['column', { type: 'bar', stacked: false, stackType: 'normal' }],
  ['columnStacked', { type: 'bar', stacked: true, stackType: 'normal' }],
  ['columnStackedFull', { type: 'bar', stacked: true, stackType: '100%' }],
  ['bar', { type: 'bar', horizontal: true, stacked: false, stackType: 'normal' }],
  ['barStacked', { type: 'bar', stacked: true, horizontal: true, stackType: 'normal' }],
  ['barStackedFull', { type: 'bar', stacked: true, stackType: '100%', horizontal: true }],
  ['pie', { type: 'pie' }],
  ['donut', { type: 'donut' }],
  ['line', { type: 'line' }],
  ['area', { type: 'area' }],
]);

export const chartDataLabelsPositionsMapper = new Map([
  ['top-inside', { position: 'top' }],
  ['top-outside', { position: 'top' }],
  ['center-inside', { position: 'center' }],
  ['base-inside', { position: 'bottom' }],
]);

export const chartDataLabelsValues = {
  none: '',
  thousand: 'тыс',
  million: 'млн',
  milliard: 'млрд',
  trillion: 'трлн',
};

export const parseOptions = (
  config: CategoryDiagramSettings,
  seriesNames: string[],
  selectionCallback: (options: EventOptions) => Promise<void>,
  parentNode?: HTMLDivElement
): ApexOptions => {
  const direction = config.visuals.dataLabels?.direction ?? 'horizontal';
  const position = config.visuals.dataLabels?.position ?? 'top-inside';

  const splitColors: string[] =
    config.filters?.splitterValues
      ?.map((value, i) => value.color ?? defined(PaletteThemes.get(config.visuals.theme ?? 'palette1')).colors[i] ?? '')
      .concat(nextPaletteTheme) || [];

  const fontSize = config.visuals.dataLabels?.fontSize ? config.visuals.dataLabels.fontSize : 14;
  const foreColor = config.visuals.dataLabels?.color ? config.visuals.dataLabels.color : '#ffffff';
  const getPieSettings = (options: ApexOptions): ApexOptions => {
    if (config.visuals.type !== 'pie' && config.visuals.type !== 'donut') {
      return options;
    }
    if (position === 'outside') {
      if (!parentNode?.offsetWidth || parentNode.offsetWidth > 700) {
        return {
          grid: {
            padding: {
              top: 100,
              left: 20,
              right: 20,
              bottom: 100,
            },
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 100,
              },
            },
          },
        };
      }

      if (parentNode.offsetWidth < 400) {
        return {
          plotOptions: {
            pie: {
              customScale: 0.6,
              dataLabels: {
                offset: 70,
              },
            },
          },
        };
      }
      return {
        plotOptions: {
          pie: {
            customScale: 0.8,
            dataLabels: {
              offset: 100,
            },
          },
        },
      };
    }
    return {
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 0,
          },
        },
      },
    };
  };

  const baseOptions: ApexOptions = {
    colors: splitColors,
    chart: {
      defaultLocale: 'ru',
      locales: [ru],
      background: 'transparent',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      padding: {
        right: 30,
        left: 30,
      },
    },
    noData: {
      text: i18n.strings.Common.NoData,
    },
    fill: {
      colors: splitColors,
    },
    legend: {
      show: true,
      markers: {
        fillColors: splitColors,
      },
    },
    theme: {
      palette: config.visuals.theme,
      mode: 'dark',
    },
  };

  const offsetY = () => {
    if (chartTypeMapper.get(config.visuals.type)?.horizontal) {
      return 0;
    }

    if (direction === 'vertical') {
      return position === 'top-inside' ? -30 : position === 'top-outside' ? 10 : 0;
    }

    return position === 'top-outside' ? -30 : 0;
  };

  const offsetX = () => {
    if (!chartTypeMapper.get(config.visuals.type)?.horizontal) {
      return 0;
    }

    if (direction === 'vertical') {
      return position === 'top-outside' ? 30 : 0;
    }

    return position === 'top-outside' ? 50 : 0;
  };

  defined(baseOptions).dataLabels = {
    enabled: config.visuals.dataLabels?.show ?? false,
    offsetY: offsetY(),
    offsetX: offsetX(),
    textAnchor: 'middle',
    style: {
      fontSize: `${fontSize}px`,
      fontFamily: config.visuals.dataLabels?.font,
      fontWeight: 'bold',
      colors: config.visuals.dataLabels?.background?.show
        ? [config.visuals.dataLabels.background.color]
        : ['transparent'],
    },
    background: {
      enabled: true,
      foreColor,
      borderColor: 'transparent',
      opacity: (config.visuals.dataLabels?.background?.opacity ?? 100) / 100,
    },
  };

  defined(baseOptions.chart).events = {
    click: (_, __, options: EventOptions) => {
      selectionCallback(options).catch(console.error);
    },
  };

  if (!config.filters?.byPeriod.enabled) {
    defined(baseOptions).plotOptions = {
      bar: {
        horizontal: chartTypeMapper.get(config.visuals.type)?.horizontal,
        dataLabels: {
          ...chartDataLabelsPositionsMapper.get(config.visuals.dataLabels?.position ?? 'top-inside'),
          orientation: config.visuals.dataLabels?.direction,
        },
      },
    };

    defined(baseOptions).chart = {
      ...defined(baseOptions).chart,
      ...chartTypeMapper.get(config.visuals.type),
    };

    defined(baseOptions).theme = {
      ...defined(baseOptions).theme,
      palette: config.visuals.theme,
    };

    defined(baseOptions).stroke = {
      show: true,
      width: 2,
      colors: chartTypeMapper.get(config.visuals.type)?.type !== 'line' ? ['transparent'] : undefined,
    };

    defined(baseOptions).grid = {
      borderColor: config.visuals.gridLineType?.color ?? '#fff',
      strokeDashArray: config.visuals.gridLineType?.lineType === 'dotted' ? 10 : 0,
      xaxis: {
        lines: {
          show: config.visuals.gridLineType?.showYaxis,
        },
      },
      yaxis: {
        lines: {
          show: config.visuals.gridLineType?.showXaxis,
        },
      },
    };

    defined(baseOptions).yaxis = {
      show: true,
      min: 0,
      // forceNiceScale: true,
    };

    defined(baseOptions).xaxis = {
      categories: seriesNames,
      min: 1,
      labels: {
        hideOverlappingLabels: true,
      },
    };
  } else {
    defined(baseOptions).xaxis = {
      type: 'category',
    };
  }

  defined(baseOptions).responsive = [
    {
      breakpoint: 500,
      options: {
        dataLabels: {
          enabled: false,
        },
      },
    },
  ];

  return { ...baseOptions, ...getPieSettings(baseOptions) };
};
