import { ValueRound } from 'entities/dataValue';
import { round } from 'shared/lib/math';

export const roundValue = (value?: number | null, roundTo: ValueRound = 'whole') => {
  if (value === undefined || value === null) {
    return value;
  }

  let digits = 0;

  switch (roundTo) {
    case 'tens': {
      digits = 1;
      break;
    }
    case 'hundreds': {
      digits = 2;
      break;
    }

    case 'thousands': {
      digits = 3;
      break;
    }
    case 'whole':
    default: {
      digits = 0;
      break;
    }
  }

  return round(value, digits);
};
