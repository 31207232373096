import { Components } from '@mui/material/styles';
import { colors, fonts } from '../assets';

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    sx: {
      paddingLeft: 2,
      paddingRight: 2,
      height: 40,
    },
  },
  styleOverrides: {
    root: {
      ...fonts.button2,

      '&:hover': {
        boxShadow: 'none',
      },
    },
    containedError: {
      backgroundColor: colors.accent.error,
      boxShadow: '0px 4px 12px rgba(235, 42, 56, 0.3)',
    },
    containedSuccess: {
      backgroundColor: colors.accent.success,
      boxShadow: '0px 4px 12px rgba(0, 172, 95, 0.3)',
      color: colors.brand.white,
    },
  },
};
