import { ClassifierNode } from 'entities/classifier';
import { TimeStepValueRequest, ValueRequest, ValueRequestIndicator } from 'entities/dataValue';
import { CategoryDiagramSettings } from 'entities/panel';
import { DateTime } from 'luxon';
import { defined } from 'shared/lib/checks';
import { getDatesFromConfig } from 'shared/lib/period';
import { validateConfig } from './validateConfig';
import { isPieChart } from './isPieChart';

export const parseRequest = (
  config: CategoryDiagramSettings,
  currentDate = DateTime.now(),
  currentClassifierId?: string
): ValueRequest | TimeStepValueRequest | undefined => {
  if (!validateConfig(config)) {
    return;
  }

  const isPeriodEnabled = !!config.filters?.byPeriod.enabled;

  const lastClassifier = config.temporary.history?.at(-1);
  const lastNode = lastClassifier?.nodes.at(-1) || currentClassifierId;
  const firstClassifier = config.filters?.nodes[0];

  const nodeIds = [
    {
      nodeId: lastNode || firstClassifier?.nodeId || '',
      rootId: lastClassifier?.rootId || firstClassifier?.rootId || '',
    },
    ...(config.filters?.nodes.filter((node) => node.rootId !== (lastClassifier?.rootId || firstClassifier?.rootId)) ||
      []),
  ] as ClassifierNode[];

  const getGroupId = () => {
    if (isPieChart(config) || isPeriodEnabled) {
      return undefined;
    }
    return config.temporary.groupId;
  };

  const byPeriodValue = config.filters?.byPeriod.enabled
    ? getDatesFromConfig(
        defined(config.filters.byPeriod.period),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.common.globalOffset && config.common.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.common.globalOffset }
          : undefined
      )
    : undefined;

  const byPeriod = byPeriodValue && {
    start: byPeriodValue.start,
    end: byPeriodValue.end,
    step: config.filters?.byPeriod.step || 'Month',
  };

  if (isPeriodEnabled) {
    return {
      indicatorId: defined(config.filters).indicatorId,
      splitClassifier: {
        rootId: lastClassifier?.rootId || firstClassifier?.rootId || '',
        series:
          config.filters?.splitterValues?.map((value) => {
            return {
              nodeId: value.node.nodeId,
              forPeriodCalculation: value.calculationType,
              forPeriodAggregation: value.aggregationType,
              nodesAggregation: config.classifiers.aggregationType,
            };
          }) || [],
      },
      falling: lastClassifier
        ? {
            sourceNodeId: lastClassifier.rootId,
            nodeId: lastNode,
          }
        : null,
      classifiers: nodeIds,
      byPeriod,
      withChildrenSum: true,
    };
  }

  const request: ValueRequest = {
    byIndicators: defined(config.filters?.splitterValues).map((value) => {
      const period = getDatesFromConfig(
        defined(value.period),
        currentDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
        undefined,
        config.common.globalOffset && config.common.globalOffset > 0
          ? { hasOffset: true, offsetDays: config.common.globalOffset }
          : undefined
      );

      return {
        key: value.key,
        indicatorId: defined(config.filters).indicatorId,
        period: config.filters?.byPeriod.enabled
          ? undefined
          : {
              start: period.start,
              end: period.end,
            },
        nodeIds: [value.node],
        forPeriodAggregation: value.aggregationType,
        forPeriodCalculation: value.calculationType,
        withChildrenSum: config.classifiers.childrenSumEnabled,
        nodesAggregation: config.classifiers.aggregationType,
      } as ValueRequestIndicator;
    }),
    nodeIds,
    byPeriod,
    forPeriodAggregation: config.filters?.aggregationType,
    forPeriodCalculation: config.filters?.calculationType,
    withChildrenSum: true,
    nodesAggregation: config.classifiers.aggregationType,
    fillClassifiers: true,
    fillIndicators: false,
    groupByRootId: getGroupId(),
  };
  return request;
};
