import { DateTime } from 'luxon';

/**
 * Расчёт даты окончания для квартала на основе опорной даты.
 * @param {DateTime} date Опорная дата.
 * @returns {DateTime} Дата окончания.
 */
export function getQuarterEndByDate(date: DateTime) {
  if (date.month >= 1 && date.month < 4) {
    return date.set({ day: 31, month: 3 });
  } else if (date.month >= 4 && date.month < 7) {
    return date.set({ day: 30, month: 6 });
  } else if (date.month >= 7 && date.month < 10) {
    return date.set({ day: 30, month: 9 });
  } else {
    return date.set({ day: 31, month: 12 });
  }
}
