import { DateTime } from 'luxon';
import { RangeRequest } from 'shared/types';

/**
 * Функция для смещения интервала дат на эквивалентный интервал назад.
 * @param range Исходный интервал.
 * @returns Интервал, смещённый относительно исходного назад и равный ему.
 */
export function getPeriodAsOffset(range: RangeRequest): RangeRequest {
  const start = DateTime.fromJSDate(new Date(range.start));
  const end = DateTime.fromJSDate(new Date(range.end));
  const daysBetween = end.diff(start, ['days']).days;

  return {
    start: start.minus({ days: daysBetween }).toJSDate().toISOString(),
    end: range.start,
  } as RangeRequest;
}
