import { defined } from '../checks';

export const replaceURLParam = (name: string, value?: string) => {
  const origin = window.location.href.split('//');
  if (origin.length !== 2) {
    return;
  }

  const pathNameParams = defined(origin[1])
    .split('/')
    .filter((part) => part !== '');
  const paramIndex = pathNameParams.findIndex((param) => param.toLowerCase() === name.toLowerCase());

  if (value) {
    if (paramIndex === -1) {
      pathNameParams.push(name);
      pathNameParams.push(value);
    } else {
      if (paramIndex + 1 < pathNameParams.length) {
        pathNameParams.splice(paramIndex + 1, 1, value);
      } else {
        pathNameParams.push(value);
      }
    }
  } else {
    if (paramIndex + 1 < pathNameParams.length && paramIndex > -1) {
      pathNameParams.splice(paramIndex, 2);
    }
  }

  history.replaceState(null, '', `${defined(origin[0])}//${pathNameParams.join('/')}`);
};
