import { Components } from '@mui/material/styles';
import { colors, fonts } from '../assets';

export const MuiTooltip: Components['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
    placement: 'top',
    enterTouchDelay: 5,
  },
  styleOverrides: {
    tooltip: {
      ...fonts.caption1,
      textAlign: 'center',
      width: '200px',
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.25)',
      borderRadius: '4px',
      padding: '4px',
    },
    arrow: {
      color: colors.local.black90,
    },
    tooltipPlacementTop: {
      marginBottom: '8px !important',
    },
    tooltipPlacementBottom: {
      marginTop: '8px !important',
    },
  },
};
