import { Components } from '@mui/material/styles';

export const MuiInputAdornment: Components['MuiInputAdornment'] = {
  styleOverrides: {
    positionEnd: {
      marginLeft: 0,
    },
    root: {
      paddingRight: '8px',
    },
  },
};
