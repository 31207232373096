import auth from 'shared/api/auth';
import events from 'entities/event/events';
import userStore from 'entities/user/userStore';
import { ConnectionSettings, ConnectionState } from 'entities';
import { getHost } from 'shared/lib/url';
import { defined } from 'shared/lib/checks';
import { ReconnectTimeout, CountdownStep } from './config/connection';
import contextStore from 'entities/context/contextStore';
import panelsStore from 'entities/panel/panelsStore';
import templateStore from 'entities/data/templates/templateStore';

export type StateChangedHandler = (state: ConnectionState) => void;
export type CountdownHandler = (value: number) => void;
export type ServerVersionResponse = { version: string };

class Connection {
  private _stateCallbacks: StateChangedHandler[] = [];
  private _countdownCallbacks: CountdownHandler[] = [];
  private _countdown: number | undefined = undefined;
  private _errorsFired = false;

  public settings: ConnectionSettings | undefined;

  constructor() {
    void this.startupFlow();
  }

  public onStateChanged(callback: StateChangedHandler): void {
    this._stateCallbacks.push(callback);
  }

  public offStateChanged(callback: StateChangedHandler): void {
    this._stateCallbacks = this._stateCallbacks.filter((x) => x !== callback);
  }

  private fireStateChanged(state: ConnectionState): void {
    this._stateCallbacks.forEach((x) => x(state));
  }

  public onCountdown(callback: CountdownHandler): void {
    this._countdownCallbacks.push(callback);
  }

  public offCountdown(callback: CountdownHandler): void {
    this._countdownCallbacks = this._countdownCallbacks.filter((x) => x !== callback);
  }

  private fireCountdown(value: number): void {
    this._countdownCallbacks.forEach((x) => x(value));
  }

  private countdown(): void {
    if (this._countdown === undefined) {
      this._countdown = ReconnectTimeout;
      this.fireCountdown(this._countdown);
      setTimeout(() => this.countdown(), CountdownStep * 1000);
    } else {
      const newValue = this._countdown - CountdownStep;
      if (newValue > 0) {
        this._countdown = newValue;
        this.fireCountdown(this._countdown);
        setTimeout(() => this.countdown(), CountdownStep * 1000);
      } else {
        this._countdown = undefined;
        void this.startupFlow();
      }
    }
  }

  private async loadConnectionSettings(): Promise<ConnectionSettings> {
    const host = getHost();
    const response = await fetch(`${host}/supply-auth/config`);
    const connectionSettings = await (response.json() as Promise<ConnectionSettings>);
    return connectionSettings;
  }

  private async initAuth(): Promise<boolean> {
    if (!this.settings) {
      return false;
    }

    await auth.init(this.settings);
    return true;
  }

  private async startupFlow(): Promise<void> {
    try {
      this.fireStateChanged('Connecting');

      // Настройки подключения.
      this.settings = await this.loadConnectionSettings();

      // Авторизация.
      await this.initAuth();

      // SignalR.
      await events.connect();

      // Загрузка информации о пользователе.
      await userStore.loadUser();
      // Загрузка контекстов пользователя.
      await contextStore.loadContexts();
      // Загрузка роли пользователя в контексте.
      await userStore.loadContextRole(defined(contextStore.currentContextId));
      // Загрузка панелей пользователя.
      await panelsStore.loadPanels(defined(contextStore.currentContextId));
      // Загрузка шаблонов пользователя
      await templateStore.loadTemplates(defined(contextStore.currentContextId));

      this._errorsFired = false;
      this.fireStateChanged('Connected');
    } catch (ex) {
      this._errorsFired = true;
      this.fireStateChanged('Error');
      this.countdown();
    }
  }
}

export default new Connection();
