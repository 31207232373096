import { DateTime } from 'luxon';
import { SlidingValue } from 'shared/types';

/**
 * Функция для смещения даты.
 * @param {DateTime} value Дата для смещения.
 * @param {SlidingValue} offset Смещение.
 * @returns {DateTime} Дата с учётом смещения.
 */
export function applyOffset(value: DateTime, offset: SlidingValue) {
  switch (offset.range) {
    case 'year': {
      return value.minus({ years: offset.value });
    }
    case 'quarter': {
      return value.minus({ quarters: offset.value });
    }
    case 'month': {
      return value.minus({ months: offset.value });
    }
    case 'week': {
      return value.minus({ weeks: offset.value });
    }
    case 'day':
    default: {
      return value.minus({ days: offset.value });
    }
  }
}
