import { Blocks, Template } from 'entities';
import { makeAutoObservable, runInAction } from 'mobx';
import { defined } from 'shared/lib/checks';
import notificationsStore from 'entities/notification/notificationsStore';
import i18n from 'entities/localization/i18n';
import { addTemplate, deleteTemplate, loadTemplates, updateTemplate } from './api';
import contextStore from 'entities/context/contextStore';
import { cloneDeep } from 'lodash';

class TemplateStore {
  /**
   * Маркер загрузки данных.
   */
  public loading = true;
  /**
   * Список шаблонов.
   */
  private _templates: Template[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  public templates(type: Blocks) {
    return this._templates.filter((tmplt) => tmplt.type === type);
  }

  public templateContent<T>(templateId: number) {
    const template = this._templates.find((tmplt) => tmplt.id === templateId);

    return cloneDeep(template?.content as T | undefined);
  }

  public async loadTemplates(contextId: number) {
    runInAction(() => {
      this.loading = true;
      this._templates = [];
    });

    try {
      const _templates = await loadTemplates(contextId);
      runInAction(() => {
        this._templates = _templates;
      });
    } catch (ex) {
      notificationsStore.notify('error', i18n.strings.Errors.Templates.LoadError.Title, (ex as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async addTemplate(template: Template) {
    const newTemplate = await addTemplate(defined(contextStore.currentContextId), template);
    const resultTemplate = await updateTemplate(defined(contextStore.currentContextId), {
      ...template,
      id: newTemplate.id,
    });
    runInAction(() => {
      this._templates = [...this._templates, template];
    });
    return resultTemplate;
  }

  public async updateTemplate(template: Template) {
    const templatesUpdated = this._templates;
    const index = templatesUpdated.findIndex((tmplt) => tmplt.id === template.id);
    if (index === -1) {
      await this.addTemplate(template);
    } else {
      await updateTemplate(defined(contextStore.currentContextId), template);
    }
    runInAction(() => {
      templatesUpdated.splice(index, 1, template);
      this._templates = templatesUpdated;
    });
  }

  public async deleteTemplate(templateId: number) {
    const templatesUpdated = this._templates;
    const index = templatesUpdated.findIndex((tmplt) => tmplt.id === templateId);
    if (index === -1) {
      return;
    } else {
      await deleteTemplate(defined(contextStore.currentContextId), templateId);
    }
    runInAction(() => {
      templatesUpdated.splice(index, 1);
      this._templates = templatesUpdated;
    });
  }
}

export default new TemplateStore();
