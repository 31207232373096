import { PaletteOptions } from '@mui/material/styles';
import { colors } from '../theme/assets';

export const palette: PaletteOptions = {
  primary: {
    main: colors.brand.primary,
  },
  secondary: {
    main: colors.brand.secondary,
  },
  success: {
    main: colors.accent.success,
  },
  warning: {
    main: colors.accent.warning,
  },
  error: {
    main: colors.accent.error,
  },
  text: {
    primary: colors.text.primary,
    secondary: colors.text.secondary,
  },
  background: {
    default: colors.background.secondary,
    paper: colors.background.secondary,
  },
};
