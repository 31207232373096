import { Components } from '@mui/material/styles';

export const MuiBadge: Components['MuiBadge'] = {
  defaultProps: {
    color: 'secondary',
  },
  styleOverrides: {
    badge: {
      fontSize: 9,
      lineHeight: 12,
      height: 16,
      minWidth: 16,
      borderRadius: 8,
      padding: 0,
    },
  },
};
