import { getLocalSettings, setLocalSettings } from 'shared/lib/localSettings';
import { LocalSettings } from './localSettings';

export function setLastUsedPanelId(contextId: number | null, panelId: number | null): void {
  if (!contextId || !panelId) {
    return;
  }

  const settings = getLocalSettings<LocalSettings>('application_settings');
  const panels = settings.lastPanelsList ?? [];
  const index = panels.findIndex((panel) => panel.contextId === contextId);

  if (index >= 0) {
    panels.splice(index, 1, { contextId, panelId });
  } else {
    panels.push({ contextId, panelId });
  }

  settings.lastPanelsList = panels;
  setLocalSettings('application_settings', settings);
}
