import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      borderRadius: 4,
      // backgroundColor: colors.brand.white,
      // предупреждение
      '&.warning fieldset': {
        borderColor: `${colors.accent.warning} !important`,
      },
      '&.warning': {
        backgroundColor: `${colors.accent.warningBackground} !important`,
      },
      '& .MuiOutlinedInput-root.MuiOutlinedInput-root.MuiOutlinedInput-root': {
        paddingRight: '2px',
      },
      '& input[type="number"].MuiOutlinedInput-input': {
        paddingRight: '0px',
      },
      '& input[type="number"]::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
    },
  },
};
