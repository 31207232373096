import { weekends } from 'shared/config/dates';
import { WeekDays } from 'shared/types';

export const isWeekend = (days: WeekDays[]) => {
  if (days.length !== weekends.length) {
    return false;
  }

  let result = true;

  weekends.forEach((weekday) => {
    if (days.some((day) => day !== weekday)) {
      result = false;
    }
  });

  return result;
};
