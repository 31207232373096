import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { colors, fonts } from '../../theme/assets';

export type ErrorProps = {
  text: string;
};

const Error: React.FC<ErrorProps> = ({ text }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.accent.error,
        borderRadius: 1,
        p: 1,
      }}
    >
      <Typography color="error" sx={{ ...fonts.body2 }}>
        {text}
      </Typography>
    </Box>
  );
};

export default Error;
