import queryString from 'query-string';
import api from 'shared/api';
import { ExistanceStatus } from 'shared/types';
import { Classifier } from '../model/classifier';

export async function loadClassifiers(contextId: number, parentId?: string, includeArchived = false) {
  const parent = parentId ? `/${parentId}` : '';
  const statuses: ExistanceStatus[] = includeArchived ? ['Active', 'Archived'] : ['Active'];

  return await api.get<Classifier[]>(
    queryString.stringifyUrl({
      url: `/contexts/${contextId}/classifiers/children${parent}`,
      query: { statuses: statuses },
    })
  );
}
