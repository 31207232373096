import { Components } from '@mui/material/styles';
import { colors, paddings } from '../assets';

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    root: {
      padding: 0,
      borderRadius: paddings.values.quater,
      color: colors.icon.primary,
      '&:hover': {
        color: colors.buttons.hover,
        backgroundColor: 'transparent',
      },
    },
    sizeSmall: {
      '& svg': {
        width: 24,
        height: 24,
      },
    },
  },
};
