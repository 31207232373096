import { Components } from '@mui/material/styles';
import { colors, fonts } from '../assets';

export const MuiToggleButton: Components['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      ...fonts.button1,
      color: colors.text.primary,
      borderRadius: '4px 4px 4px 4px',
      '&.Mui-selected': {
        backgroundColor: colors.accent.active,
        color: colors.brand.white,
        '&:hover': {
          backgroundColor: colors.accent.active,
        },
      },
    },
  },
};
