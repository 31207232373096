import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiPaginationItem: Components['MuiPaginationItem'] = {
  styleOverrides: {
    root: {
      '&.MuiButtonBase-root': {
        borderRadius: '2px',

        '&.Mui-selected': {
          backgroundColor: colors.buttons.active,
          color: 'white',
        },

        '&:hover': {
          backgroundColor: colors.background.secondaryHover,
          color: 'black',
        },
      },
    },
  },
};
