import { observer } from 'mobx-react';
import { colors, fonts } from 'shared/theme/assets';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useLocalization } from 'entities/localization';

export type MessageNotificationProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  includeMessageInTitle?: boolean;
  handleClick?: () => void;
};

const MessageNotification: React.FC<MessageNotificationProps> = ({
  title,
  subtitle,
  includeMessageInTitle = false,
  handleClick,
}) => {
  const { L } = useLocalization();

  return (
    <Stack sx={{ maxWidth: '224px' }}>
      <Typography sx={{ ...fonts.body2 }}>{title}</Typography>
      {includeMessageInTitle && subtitle && <Typography sx={{ ...fonts.body2 }}>{subtitle}</Typography>}
      {handleClick && (
        <Button
          sx={{
            ...fonts.body3,
            backgroundColor: '#ffffff',
            width: '80px',
            height: '28px',
            color: colors.text.brand,
            textTransform: 'capitalize',
            mt: '12px',
            '&:hover': {
              backgroundColor: '#ffffff',
            },
          }}
          variant="outlined"
          onClick={handleClick}
        >
          {L.Common.Navigate}
        </Button>
      )}
    </Stack>
  );
};

export default observer(MessageNotification);
