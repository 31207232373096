import { Components } from '@mui/material/styles';
import { fonts } from '../assets';

export const MuiChip: Components['MuiChip'] = {
  styleOverrides: {
    root: {
      ...fonts.caption2,
      height: '24px',
      borderRadius: 2,
    },
    label: {
      flexShrink: 1,
    },
    deleteIcon: {
      flexShrink: 0,
      color: 'currentcolor',
    },
  },
};
