import { Components } from '@mui/material/styles';
import { colors, fonts, paddings } from '../assets';

export const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      ...fonts.body3,
      backgroundColor: colors.background.secondary,
      minHeight: 40,
      '& .MuiOutlinedInput-input': {
        paddingRight: paddings.values.main, // местоположение правых доп.кнопок
      },
      '& fieldset': {
        borderColor: colors.border.active,
      },

      // поле ввода
      '&.MuiOutlinedInput-root': {
        boxSizing: 'border-box',
        paddingRight: paddings.values.main, // местоположение правых доп.кнопок

        '& fieldset': {
          boxSizing: 'border-box',
        },

        // фокус
        '&.Mui-focused fieldset': {
          borderWidth: '2px !important',
          borderColor: `${colors.border.focus} !important`,
        },

        // недоступно
        '&.Mui-disabled fieldset': {
          color: colors.text.disabled,
          borderColor: colors.border.disabled,
        },

        // ошибка
        '&.Mui-error fieldset': {
          borderColor: `${colors.accent.error} !important`,
        },
      },

      // по наведению
      '&.MuiOutlinedInput-root:hover': {
        '& fieldset': {
          borderColor: colors.border.hover,
        },
        '&.Mui-error fieldset': {
          borderColor: colors.accent.error,
        },
      },
    },
  },
};
