import { createTheme } from '@mui/material/styles';
import { ruRU } from '@mui/material/locale';
import { palette } from './pallete';
import { components } from './components';
import darkScrollbar from '@mui/material/darkScrollbar';

const theme = createTheme(
  {
    palette: {
      ...palette,
      mode: 'dark',
    },
    components: {
      ...components,
      MuiCssBaseline: {
        styleOverrides: (themeParam) => ({
          body: themeParam.palette.mode === 'dark' ? darkScrollbar() : null,
        }),
      },
    },
    shape: {
      borderRadius: 4,
    },
    typography: {
      fontFamily: ['Roboto', 'Arial'].join(','),
    },
  },
  ruRU
);

export default theme;
