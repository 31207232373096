import { weekdays } from 'shared/config/dates';
import { WeekDays } from 'shared/types';

export const areWeekdays = (days: WeekDays[]) => {
  if (days.length !== weekdays.length) {
    return false;
  }

  weekdays.forEach((weekday) => {
    if (days.some((day) => day !== weekday)) {
      return false;
    }
  });

  return true;
};
