import { DateTime } from 'luxon';

/**
 * Расчёт даты окончания для накопительного квартала.
 * @param {DateTime} date Опорная дата.
 * @param {FullQuarter} quarter Квартал с учётом года.
 * @returns {DateTime} Дата окончания.
 */
export function getQuarterEnd(date: DateTime, quarter: number) {
  if (quarter === 0) {
    quarter = date.quarter;
  }

  if (quarter === 1) {
    return date.set({ day: 31, month: 3 });
  } else if (quarter === 2) {
    return date.set({ day: 30, month: 6 });
  } else if (quarter === 3) {
    return date.set({ day: 30, month: 9 });
  } else {
    return date.set({ day: 31, month: 12 });
  }
}
