import { getLocalSettings } from 'shared/lib/localSettings';
import { LocalSettings } from './localSettings';
import { getURLParam } from 'shared/lib/url';
import { panelURLParamName } from '../config';

export function getLastUsedPanelId(contextId: number): number | null {
  const URLPanelId = getURLParam(panelURLParamName) ? Number(getURLParam(panelURLParamName)) : undefined;

  if (URLPanelId) {
    return URLPanelId;
  } else {
    const panels = getLocalSettings<LocalSettings>('application_settings').lastPanelsList ?? [];
    return panels.find((panel) => panel.contextId === contextId)?.panelId ?? null;
  }
}
