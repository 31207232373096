import { DateTime } from 'luxon';
import { SlidingValue } from 'shared/types';
import { applyOffset } from './applyOffset';

/**
 * Функция получения даты начала запрашиваемого периода.
 * @param {DateTime} date Дата окончания.
 * @param {SlidingValue} range Период, который хотим забрать.
 * @param {boolean} calendarBinding Привязка к календарю.
 * @returns {DateTime} Дата начала периода.
 */
export function getStartDate(date: DateTime, range: SlidingValue, calendarBinding: boolean) {
  // Без привязки к календарю компенсируем текущий день
  const start = calendarBinding ? applyOffset(date, range) : applyOffset(date, range).plus({ days: 1 });

  switch (range.range) {
    case 'day': {
      // Если период указан в 1 день, то смещение не производится, так как это тот-же день.
      // Смещение даты окончания производится после проведения всех расчётов, поэтому 1 день становится неизменной датой начала +1 день для конца дня.
      return range.value === 1 ? date : start;
    }
    case 'month': {
      // Для привязки к календарному месяцу мы получаем за дату окончания конец соответствующего месяца,
      // который при вычитании из него n месяцев даст конец другого месяца, который выходит за требуемый диапазон,
      // так как нам нужно учесть текущий месяц в выборке
      return calendarBinding ? start.plus({ months: 1 }).set({ day: 1 }) : start;
    }
    case 'week':
    case 'quarter':
    case 'year': {
      // Компенсируем  текущий день для календарного значения
      return calendarBinding ? start.plus({ days: 1 }) : start;
    }
    case 'fullQuarter': {
      return start.set({ month: 1, day: 1 });
    }
    default: {
      return start;
    }
  }
}
