export const PaletteThemes = new Map([
  ['palette1', { colors: ['#3FA0FC', '#50E8A6', '#FFBC4B', '#FF6578', '#8C75D7'], label: 'Палитра 1' }],
  ['palette2', { colors: ['#5B6CC1', '#46B6F6', '#6ABC6C', '#FAD64F', '#FFA841'], label: 'Палитра 2' }],
  ['palette3', { colors: ['#5ABEE4', '#6E848E', '#DC6D84', '#51DEB7', '#B3A79D'], label: 'Палитра 3' }],
  ['palette4', { colors: ['#6E848E', '#6ED4CD', '#CFF67E', '#95DAFC', '#FF8181'], label: 'Палитра 4' }],
  ['palette5', { colors: ['#51A1A0', '#FBB1B1', '#A1F192', '#FD6361', '#82D9EB'], label: 'Палитра 5' }],
]);

export const nextPaletteTheme = [
  '#1F77B4',
  '#2CA02C',
  '#D62728',
  '#9467BD',
  '#8C564B',
  '#E377C2',
  '#7F7F7F',
  '#BCBD22',
  '#17BECF',
  '#AEC7E8',
  '#98DF8A',
  '#FF9896',
  '#C5B0D5',
  '#C49C94',
  '#F7B6D2',
  '#C7C7C7',
  '#DBDB8D',
  '#9EDAE5',
  '#FFBB78',
  '#2B83BA',
  '#AB63FA',
  '#FFA15A',
  '#19D3F3',
  '#FF6692',
  '#B6E880',
  '#FF7F0E',
  '#2A9D8F',
  '#E63946',
  '#A8DADC',
  '#457B9D',
  '#1D3557',
  '#F4A261',
  '#E76F51',
  '#D4A5A5',
  '#4C6A92',
  '#718355',
  '#B4CDED',
  '#C94D98',
  '#67B7D1',
  '#5B5D8C',
  '#EFB205',
  '#A3A948',
  '#ED5565',
  '#DAA520',
  '#2F4F4F',
];
