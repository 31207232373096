import { DateTime } from 'luxon';
import { RangeRequest, RangeStatic } from 'shared/types';
import { endPeriodDisplayedToInternal } from './endPeriodDisplayedDoInternal';

/**
 * Функция получения периода дат из статического конфига.
 * @param {RangeSliding} range Конфиг статического периода.
 * @param {boolean} toInternalPeriod Привести период к внутреннему (Начало следующего дня).
 * @returns {RangeRequest} Период дат.
 */
export function getStaticDates(range: RangeStatic, date = new Date(), toInternalPeriod = true): RangeRequest {
  let start = DateTime.fromISO(range.start);
  let end = DateTime.fromISO(range.end);

  if (!range.fixedYear) {
    const now = DateTime.fromJSDate(date);
    const currentYear = now.year;
    start = start.set({ year: currentYear });
    end = end.set({ year: currentYear });

    if (start > end) {
      const yearStart = now.set({ day: 1, month: 1 }); // 1 января текущего года

      if (start <= now && now < yearStart.plus({ years: 1 })) {
        end = end.set({ year: currentYear + 1 });
      }
      if ((yearStart <= now && now < end) || (now < start && now > end)) {
        start = start.set({ year: currentYear - 1 });
      }
    } else {
      if (now < start) {
        // Берём предыдущий период, если не попадаем в текущий
        start = start.minus({ years: 1 });
        end = end.minus({ years: 1 });
      }
    }
  }

  if (range.toCurrentDate) {
    const now = DateTime.now();
    if (now >= start && now < end) {
      end = end.set({ day: now.day, month: now.month, year: now.year });
    }
  }

  return {
    start: start.toJSDate().toISOString(),
    end: (toInternalPeriod ? endPeriodDisplayedToInternal(end.toJSDate()) : end.toJSDate()).toISOString(),
  } as RangeRequest;
}
