import React from 'react';
import connection from '../connection';
import { ConnectionState } from 'entities';
import { ReconnectTimeout } from '../config/connection';
import { configMUI } from 'shared/config/mui';

export function useStartup() {
  const [state, setState] = React.useState<ConnectionState>('Connecting');
  const [countdown, setCountdown] = React.useState<number>(ReconnectTimeout);

  configMUI();

  const updateState = React.useCallback((value: ConnectionState) => setState(value), []);
  const updateCountdown = React.useCallback((value: number) => {
    setCountdown(value);
  }, []);

  React.useLayoutEffect(() => {
    connection.onStateChanged(updateState);
    connection.onCountdown(updateCountdown);
    return () => {
      connection.offStateChanged(updateState);
      connection.offCountdown(updateCountdown);
    };
  });

  return {
    state,
    countdown,
  };
}
