import { ContextRole, Permission, User } from 'entities';
import { makeAutoObservable, runInAction } from 'mobx';
import { getUser, getRole } from './api';
import notificationsStore from 'entities/notification/notificationsStore';
import i18n from 'entities/localization/i18n';

class UserStore {
  /**
   * Маркер загрузки данных.
   */
  public loading = true;
  /**
   * Текущая роль пользователя.
   */
  public role: ContextRole | null = null;
  /**
   * Данные о текущем пользователе.
   */
  public user: User | null = null;
  /**
   * Признак, является-ли пользователь администратором.
   */
  public isAdministrator = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Загрузка данных о текущем пользователе.
   */
  public async loadUser() {
    runInAction(() => {
      this.loading = true;
      this.user = null;
    });

    try {
      const user = await getUser(); // Логирование входа пользователя в систему (без указания контекста) (на сервере, автоматически)
      runInAction(() => {
        this.user = user;
      });
    } catch (ex) {
      notificationsStore.notify('error', i18n.strings.Errors.Users.LoadError.Title, (ex as Error).message);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public async loadContextRole(contextId: number | null) {
    const role = contextId ? await getRole(contextId) : null;
    runInAction(() => {
      this.role = role;
      this.isAdministrator = (role?.abilities ?? []).findIndex((x) => x.ability === 'DoEverything') !== -1;
    });
  }

  public checkPermission(permission?: Permission, checkIsAdmin = true): boolean {
    if (checkIsAdmin && this.isAdministrator) {
      return true;
    }

    return this.role?.abilities.some((ability) => ability.ability === permission) ?? false;
  }
}

export default new UserStore();
