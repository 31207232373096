/**
 * @deprecated
 * Gets UTC date from local date
 * @param {Date} localDate Local date
 * @returns {Date} UTC date
 */
export function utcFromLocalDate(localDate: Date): Date {
  const utc = Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), 0, 0, 0);
  return new Date(utc);
}
