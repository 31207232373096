import { Components } from '@mui/material/styles';

export const MuiCardContent: Components['MuiCardContent'] = {
  styleOverrides: {
    root: {
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  },
};
