import { Components } from '@mui/material/styles';
import { colors, fonts } from '../assets';

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root': {
        paddingRight: '6px !important',
        flexWrap: 'nowrap',
        overflow: 'hidden',
        height: 40,
      },
    },
    paper: {
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: colors.border.active,
      borderRadius: 2,
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
    },
    listbox: {
      paddingTop: 0.5,
      paddingBottom: 0.5,
    },
    option: {
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
      ...fonts.body2,
    },
    noOptions: {
      ...fonts.body2,
    },
    loading: {
      ...fonts.body2,
    },
    endAdornment: {
      position: 'initial',
      display: 'flex',
      flexWrap: 'nowrap',

      '& .MuiAutocomplete-clearIndicator': {
        visibility: 'visible',
      },
    },
    tag: {
      maxWidth: 110,
    },
  },
};
