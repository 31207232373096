import { DateRange } from 'shared/types';
import { getDefaultStaticRange } from './getDefaultStaticRange';

/**
 * Получение диапазона дат по-умолчанию (Статический диапазон).
 * @returns {DateRange} Диапазон по-умолчанию (включает статический диапазон по-умолчанию).
 */
export function getDefaultRange(toInternalPeriod = true): DateRange {
  return {
    type: 'static',
    static: getDefaultStaticRange(toInternalPeriod),
  } as DateRange;
}
