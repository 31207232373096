import { Classifier } from 'entities/classifier';
import { CardSettings } from '../model';

export const getNextRootId = (index: number, config: CardSettings, roots: Classifier[], currentRoot?: Classifier) => {
  const idsOrder = config.classifiers.drillDown.drillDownOrder ?? [];

  if (idsOrder.length > 0 && idsOrder.length - 1 >= index) {
    return idsOrder[index];
  } else {
    if (currentRoot) {
      return currentRoot.innerId;
    } else {
      return config.filters?.nodes[0]?.rootId;
    }
  }
};
