import { RangeSliding, SlidingValue } from 'shared/types';

/**
 * Получение статического диапазона в формате конфигурации для сохранения в настройках.
 * @returns {RangeSliding} Скользящий диапазон по-умолчанию.
 */
export function getDefaultSlidingRange(): RangeSliding {
  return {
    range: {
      range: 'month',
      value: 1,
    } as SlidingValue,
    haveOffset: false,
    calendarBinding: false,
    toCurrentDate: false,
  } as RangeSliding;
}
