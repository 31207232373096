import { DateTime } from 'luxon';

export const reviveDateTime = (key: unknown, value: unknown) => {
  const reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/; // startswith: 2015-04-29T22:06:55
  if (typeof value == 'string' && reDateDetect.exec(value)) {
    return DateTime.fromISO(value);
  } else {
    return value;
  }
};
