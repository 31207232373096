import { BlockSizing } from '../model';

export const iconSize = (sizing: BlockSizing) => {
  switch (sizing) {
    case 'Small':
    case 'Cell':
    case 'Large':
    case 'Medium':
    default: {
      return { height: '24px', width: '24px' };
    }
  }
};
