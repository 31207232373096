import { WeekDays } from 'shared/types';
import { areWeekdays, isWeekend, isWholeWeek } from 'shared/lib/dates';
import { daysSortable } from 'shared/config/dates';

export const getDaysText = (
  days: WeekDays[],
  dayTexts: Record<WeekDays, string>,
  weekDaysText = 'Weekdays',
  weekendText = 'Weekend',
  everyDayText = 'Every day'
) => {
  if (days.length === 0) {
    return '';
  }

  const daysSorted = daysSortable
    .filter((day) => days.some((value) => value === day.day))
    .sort((a, b) => a.value - b.value)
    .map((day) => day.day);

  if (areWeekdays(daysSorted)) {
    return weekDaysText;
  }

  if (isWeekend(daysSorted)) {
    return weekendText;
  }

  if (isWholeWeek(daysSorted)) {
    return everyDayText;
  }

  return daysSorted.map((day) => dayTexts[day]).join(', ');
};
