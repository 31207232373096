import { CardSettings } from '../model';

export const validateConfig = (config: CardSettings) => {
  let isValid = !!config.filters?.indicatorId;
  isValid =
    (!!config.filters?.splitterValues?.length && config.filters.splitterValues.length > 0) ||
    (!!config.filters?.nodes && config.filters.nodes.length > 0);

  return isValid;
};
