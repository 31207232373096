import { SlidingValue } from 'shared/types';

/**
 * Функция представления выбираемого периода в виде числового значения для последующего сравнения.
 * @param {SlidingValue} range Объект периода.
 * @returns {number} Числовое представление периода.
 */
export function getRangeAsNumber(range: SlidingValue) {
  switch (range.range) {
    case 'year': {
      return 5;
    }
    case 'quarter':
    case 'fullQuarter': {
      return 4;
    }
    case 'month': {
      return 3;
    }
    case 'week': {
      return 2;
    }
    case 'day':
    default: {
      return 1;
    }
  }
}
