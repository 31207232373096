import { Components } from '@mui/material/styles';
import { paddings } from '../assets';

export const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      marginLeft: 0,
      marginTop: paddings.values.quater,
    },
  },
};
