import { DateTime } from 'luxon';
import { WeekDays } from 'shared/types';
import { getWeekEndDay } from './getWeekEndDay';
import { DAYS_IN_WEEK } from 'shared/config/dates';

/**
 * Расчёт даты окончания для выбранного периода неделя с учётом дня начала недели.
 * @param {DateTime} date Опорная дата.
 * @param {WeekDays} startDay День начала недели.
 * @returns {DateTime} Дата окончания.
 */
export function getWeekEndOffset(date: DateTime, startDay?: WeekDays) {
  const weekEndDay = getWeekEndDay(startDay ?? 'Monday');
  const currentWeekDay = date.weekday;

  if (weekEndDay >= currentWeekDay) {
    return date.plus({ days: weekEndDay - currentWeekDay });
  } else {
    return date.plus({ days: DAYS_IN_WEEK - currentWeekDay + weekEndDay });
  }
}
