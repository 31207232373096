import { DateTime } from 'luxon';
import { DefaultDateFormat, DefaultTimeFormat, DefaultDateTimeFormat } from '../../config/dates';

export type FormatOptions = {
  onlyDate?: boolean;
  onlyTime?: boolean;
};

/**
 * Преобразование в пользовательскому формату
 */
export function formatDate(
  value?: string | Date | DateTime /** do not use Daypilot.Date here. Use formatDaypilotDate instead. */,
  options: FormatOptions = { onlyDate: false, onlyTime: false }
): string {
  // TODO: avoid such behavior?
  if (!value) {
    return '';
  }

  const { onlyDate, onlyTime } = options;

  const dateTime =
    value instanceof Date
      ? DateTime.fromISO(value.toISOString())
      : value instanceof DateTime
      ? value
      : DateTime.fromISO(value);
  const format = onlyDate ? DefaultDateFormat : onlyTime ? DefaultTimeFormat : DefaultDateTimeFormat;

  const result = dateTime.toFormat(format);
  return result;
}
