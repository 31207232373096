import { addDurationToDate } from './addDurationToDate';

/**
 * @deprecated
 * Shifts end period date from user-friendly date to internal date (next day 00:00:00)
 * @param {Date} displayedEndDate User-friendly end period date
 * @returns {Date} Internal end period date
 */
export function endPeriodDisplayedToInternal(displayedEndDate: Date): Date {
  return addDurationToDate(displayedEndDate, 1);
}
