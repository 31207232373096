import { DateTime } from 'luxon';
import { CommonOffset, RangeRequest, RangeSliding } from 'shared/types';
import { defined } from '../checks';
import { shouldApplyOffset } from './shouldApplyOffset';
import { applyOffset } from './applyOffset';
import { endDateToCalendar } from './endDateToCalendar';
import { getStartDate } from './getStartDate';
import { endPeriodDisplayedToInternal } from './endPeriodDisplayedDoInternal';
import { utcFromLocalDate } from './utcFromLocalDAte';

/**
 * Функция получения периода дат из скользящего конфига.
 * @param {RangeSliding} range Конфиг скользящего периода без привязки к календарным значениям.
 * @param {Date} date Текущая опорная дата.
 * @param {Boolean} calendarBinding Учёт привзяки к календарным периодам (Неделя, месяц, год и т.д.)
 * @param {boolean} toInternalPeriod Привести период к внутреннему (Начало следующего дня).
 * @returns {RangeRequest} Период дат.
 */
export function getSlidingDates(
  range: RangeSliding,
  date: Date,
  calendarBinding: boolean,
  toInternalPeriod = true,
  commonOffset?: CommonOffset
): RangeRequest {
  let end = DateTime.fromJSDate(date);

  if (commonOffset?.hasOffset && typeof commonOffset.offsetDays === 'number') {
    end = end.minus({ days: commonOffset.offsetDays });
  }

  if (shouldApplyOffset(range, calendarBinding) === 'Start') {
    end = applyOffset(end, defined(range.offset));
  }

  if (calendarBinding) {
    end = endDateToCalendar(range, end);
  }

  let start = getStartDate(end, range.range, calendarBinding);

  if (shouldApplyOffset(range, calendarBinding) === 'End') {
    end = applyOffset(end, defined(range.offset));
    start = applyOffset(start, defined(range.offset));
  }

  if (range.toCurrentDate) {
    const now = DateTime.now();
    if (now >= start && now < end) {
      end = end.set({ day: now.day, month: now.month, year: now.year });
    }
  }
  end = DateTime.fromJSDate(
    toInternalPeriod ? endPeriodDisplayedToInternal(utcFromLocalDate(end.toJSDate())) : utcFromLocalDate(end.toJSDate())
  );

  return {
    start: utcFromLocalDate(start.toJSDate()).toISOString(),
    end: end.toISO(),
  } as RangeRequest;
}
