/**
 * @deprecated
 * Add duration in months to date
 * @param {Date} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date plus duration
 */
export function addDurationToDateMonth(dateTime: Date, duration: number): Date;
/**
 * @deprecated
 * Add duration in months to date
 * @param {String} dateTime Date
 * @param {Number} duration Duration
 * @returns {Date} Date plus duration
 */
export function addDurationToDateMonth(dateTime: Date | string, duration: number): Date {
  const date = new Date(dateTime);
  date.setMonth(date.getMonth() + duration);
  return date;
}
