const colors = {
  brand: {
    primary: '#3081FA',
    secondary: '#958AFF33',
    white: '#131313',
    white50: 'rgba(255, 255, 255, 0.50)',
  },
  text: {
    primary: '#E2E2E2',
    secondary: '#858585',
    disabled: '#9ca3af',
    brand: '#2b2aec',
    link: '#0078D2',
    linkVisited: '#57A3DC',
    lightGrey: '#7f8285',
    tertiary: '#9ca3af',
    white: '#FFFFFF',
  },
  icon: {
    primary: '#FFF',
    secondary: '#AFAFAF',
    active: '#1400DC',
    disabled: '#DADBDD',
    bgOtherCompanyIcon: '#F4F7FA',
  },
  accent: {
    blue: '#90cbf9',
    teal_lite: '#81d9d0',
    success: '#5FCE8C',
    successBackground: '#014426',
    gazprom: '#0078D2',
    error: '#F25C64',
    errorBackground: '#761017',
    warning: '#F6A85C',
    warningBackground: '#6A3E00',
    active: '#2096F3',
    activeBackground: '#143971',
    cancel: '#AFAFAF',
    cancelBackground: '#4B5563',
  },
  buttons: {
    active: '#3081FA',
    hover: '#62A1FF',
    border: '#49A7F2',
  },
  switch: {
    checked: '#2F80ED',
  },
  background: {
    primary: '#222528',
    secondary: '#2D3134',
    secondaryHover: '#45484A',
    secondaryActive: '#5C5F61',
    input: '#383D40',
    brand: '#2b2aec',
    brandLight: '#e9eafb',
    tertiary: '#f3f4f6',
    backdrop: 'rgba(0, 0, 0, 0.5)',
    heavy: '#E5E7EB',
    white10: 'rgba(255, 255, 255, 0.1)',
  },
  divider: {
    primary: '#EFEFEF',
  },
  border: {
    disabled: '#252525',
    active: '#404040',
    hover: '#646464',
    focus: '#8E8E8E',
    primary: '#5B5B5B',
  },
  opacity: {
    background: 0.1,
  },
  local: {
    icon: '#727CC0',
    active: '#3081FA',
    active3: '#5066FF08',
    active5: '#0057ff12',
    active40: '#5066ff66',
    bgLanding: '#111827',
    black: '#212121',
    black50: '#808080',
    black60: '#7a7a7a',
    black90: '#212121e5',
    grayDark: '#3F4A56',
    gray30: '#d1d6db',
    gray: '#667587',
    green: '#009688',
  },
  table: {
    headerGrey: '#2D3134',
    greySecondary: '#45484A',
    border: '#6B6B6B',
  },
  list: {
    background: '#4B5563',
  },

  tr: {
    success: '#24c38e',
  },
};

export default colors;
