import { Components } from '@mui/material/styles';

export const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    root: {
      '& .MuiBackdrop-root': {
        backgroundColor: 'transparent',
      },
    },
  },
};
