import { fonts } from 'shared/theme/assets';
import { BlockSizing } from '../model';

export const font = (sizing: BlockSizing) => {
  switch (sizing) {
    case 'Medium':
    case 'Small':
    case 'Cell': {
      return fonts.body2;
    }
    case 'Large': {
      return fonts.header4;
    }
    default: {
      return fonts.header4;
    }
  }
};
