import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiTabs: Components['MuiTabs'] = {
  styleOverrides: {
    scrollButtons: {
      width: 24,
      transition: 'width 0.5s',
      '&:hover': {
        color: colors.buttons.hover,
      },
      '&.Mui-disabled': {
        width: 0,
        transition: 'width 0.5s',
      },
    },
  },
};
