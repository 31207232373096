import api from 'shared/api';
import { Classifier } from '../model';
import { ExistanceStatus } from '../../../shared/types';

export const loadClassifiersById = async (
  contextId: number,
  ids: string[],
  nameFilter?: string,
  parentId?: string,
  withPathToRoot = false,
  withParent = false,
  includeArchived = false
): Promise<Classifier[]> => {
  const statuses: ExistanceStatus[] = includeArchived ? ['Active', 'Archived'] : ['Active'];
  const result = await api.post<Classifier[]>(`/contexts/${contextId}/classifiers/list-v2`, {
    ids: nameFilter ? undefined : ids,
    fromRootId: parentId,
    statuses: statuses,
    nameFilter,
    withPathToRoot,
    withParent,
  });

  return result.length > 0 ? result : [];
};
