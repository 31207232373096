import { DaySortable, WeekDays } from 'shared/types';

/**
 * Формат даты-времени по умолчанию во всей системе.
 */
export const DefaultDateTimeFormat = 'dd.MM.yyyy HH:mm';

export const DateTimeFormatSecs = 'dd.MM.yyyy HH:mm:ss';

/**
 * Формат даты по умолчанию во всей системе.
 */
export const DefaultDateFormat = 'dd.MM.yyyy';

/**
 * Формат времени во всей системе.
 */
export const DefaultTimeFormat = 'HH:mm';

/**
 * Количество тиков .NET TimeSpan в одной миллисекунде.
 * Подробнее: https://docs.microsoft.com/en-us/dotnet/api/system.timespan.ticks
 */
export const TicksInMilliseconds = 10000;

export const DAYS_IN_WEEK = 7;

export const DAYS_OF_WEEK: WeekDays[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

// Будни
export const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

// Выходные
export const weekends = ['Saturday', 'Sunday'];

// Дни недели с признаком сортировки
export const daysSortable: DaySortable[] = [
  {
    day: 'Monday',
    value: 1,
  },
  {
    day: 'Tuesday',
    value: 2,
  },
  {
    day: 'Wednesday',
    value: 3,
  },
  {
    day: 'Thursday',
    value: 4,
  },
  {
    day: 'Friday',
    value: 5,
  },
  {
    day: 'Saturday',
    value: 6,
  },
  {
    day: 'Sunday',
    value: 7,
  },
];
