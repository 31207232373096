import { CommonOffset, DateRange, RangeRequest } from 'shared/types';
import { getDefaultSlidingRange } from './getDefaultSlidingRange';
import { getSlidingDates } from './getSlidingDates';
import { getDefaultStaticRange } from './getDefaultStaticRange';
import { getStaticDates } from './getStaticDates';

/**
 * Функция получения периода дат на основе конфига.
 * @param {DateRange | null} config Конфиг графика для получения дат.
 * @param {Date?} date Опорная дата.
 * @returns {RangeRequest} Период дат.
 */
export const getDatesFromConfig = (
  config?: DateRange | null,
  date: Date = new Date(),
  toInternalPeriod = true,
  commonOffset?: CommonOffset
): RangeRequest => {
  switch (config?.type ?? 'static') {
    case 'sliding': {
      const configValue = config?.sliding ?? getDefaultSlidingRange();

      return getSlidingDates(configValue, date, configValue.calendarBinding, toInternalPeriod, commonOffset);
    }
    case 'static':
    default: {
      const configValue = config?.static ?? getDefaultStaticRange(toInternalPeriod);

      return getStaticDates(configValue, date, toInternalPeriod);
    }
  }
};
