import React from 'react';
import { Route, Routes } from 'react-router';

const Dashboard = React.lazy(() => import('pages/Dashboard'));
const NotFound = React.lazy(() => import('pages/NotFound'));

export enum RoutePath {
  Dashboard = '/',
  DashboardContext = '/context/:contextId',
  DashboardContextPanel = '/context/:contextId/panel/:panelId',
  NotFound = '*',
}

export type RoutePathKeys = keyof typeof RoutePath;

export const getRouteKeyByPath = (path: string): RoutePathKeys | undefined => {
  for (const key of Object.keys(RoutePath) as RoutePathKeys[]) {
    if (RoutePath[key] === path) {
      return key;
    }
  }
  return undefined;
};

export const DefaultRoute = RoutePath.Dashboard;

export const AppRoutes: React.FC = () => {
  return (
    <>
      <Routes>
        {/* Dashboard */}
        <Route path={RoutePath.Dashboard} element={<Dashboard />} />
        {/* Dashboard */}
        <Route path={RoutePath.DashboardContext} element={<Dashboard />} />
        {/* Dashboard */}
        <Route path={RoutePath.DashboardContextPanel} element={<Dashboard />} />

        {/* Not found */}
        <Route path={RoutePath.NotFound} element={<NotFound />} />
      </Routes>
    </>
  );
};
