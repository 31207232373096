import { observer } from 'mobx-react';
import React from 'react';
import { SnackbarKey, SnackbarProvider, closeSnackbar, MaterialDesignContent } from 'notistack';
import { maxSnack, anchorOrigin } from '../config/notifications';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckCircleOutlineSharpIcon from '@mui/icons-material/CheckCircleOutlineSharp';
import styled from '@mui/material/styles/styled';

export type NotificationCloseProps = {
  notificationKey: SnackbarKey;
};

const styleIcon = { marginRight: '11px', width: '22px', height: '22px' };

const NotificationClose: React.FC<NotificationCloseProps> = ({ notificationKey }) => {
  const handleSnackbarClose = React.useCallback(() => {
    closeSnackbar(notificationKey);
  }, [notificationKey]);

  return (
    <IconButton onClick={handleSnackbarClose}>
      <CloseIcon style={{ color: 'ffffff', width: '20px', height: '20px' }} />
    </IconButton>
  );
};

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent': {
    display: 'flex',
    alignItems: 'start',
    minWidth: '320px',
  },
  '#notistack-snackbar': {
    display: 'flex',
    alignItems: 'start',
  },
  '&.notistack-MuiContent-success': {
    backgroundColor: '#2e7d32',
  },
}));

const NotificationsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const snackbarClose = React.useCallback((notificationKey: SnackbarKey) => {
    return <NotificationClose notificationKey={notificationKey} />;
  }, []);

  return (
    <SnackbarProvider
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      iconVariant={{
        success: <CheckCircleOutlineSharpIcon style={styleIcon} />,
        error: <InfoOutlinedIcon style={styleIcon} />,
        warning: <WarningAmberOutlinedIcon style={styleIcon} />,
      }}
      maxSnack={maxSnack}
      preventDuplicate
      action={snackbarClose}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </SnackbarProvider>
  );
};

export default observer(NotificationsProvider);
