import { SlidingValue } from 'shared/types';
import { getRangeAsNumber } from './getRangeAsNumber';

/**
 * функция проверки диапазон смещения больше периода или меньше.
 * @param {SlidingValue} range Объект периода.
 * @param {SlidingValue} offset Объект смещения.
 * @returns {boolean} Смещение - больше периода?
 */
export function isOffsetLessThanPeriod(range: SlidingValue, offset: SlidingValue) {
  return getRangeAsNumber(offset) < getRangeAsNumber(range);
}
