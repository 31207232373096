import { Components } from '@mui/material/styles';
import { colors, fonts } from '../assets';

export const MuiSelect: Components['MuiSelect'] = {
  defaultProps: {
    size: 'small',
  },
  styleOverrides: {
    icon: {
      top: 8,
      width: 22,
      color: colors.icon.primary,
      right: 9,
    },
    select: {
      '& .MuiTypography-root': {
        ...fonts.body3,
      },
    },
  },
};
