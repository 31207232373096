import { Components } from '@mui/material/styles';
import { colors } from '../assets';

export const MuiPaper: Components['MuiPaper'] = {
  styleOverrides: {
    root: {
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundImage: 'none',
      borderColor: colors.border.active,
      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
      borderRadius: 8,
    },
    elevation1: {
      border: 'none',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
    },
  },
};
