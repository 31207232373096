import { Components } from '@mui/material/styles';
import { MuiAppBar } from './MuiAppBar';
import { MuiAutocomplete } from './MuiAutocomplete';
import { MuiBackdrop } from './MuiBackdrop';
import { MuiBadge } from './MuiBadge';
import { MuiButton } from './MuiButton';
import { MuiCardContent } from './MuiCardContent';
import { MuiCheckbox } from './MuiCheckbox';
import { MuiChip } from './MuiChip';
import { MuiDialog } from './MuiDialog';
import { MuiDialogActions } from './MuiDialogActions';
import { MuiDialogContent } from './MuiDialogContent';
import { MuiDialogTitle } from './MuiDialogTitle';
import { MuiDivider } from './MuiDivider';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiIconButton } from './MuiIconButton';
import { MuiInputAdornment } from './MuiInputAdornment';
import { MuiInputBase } from './MuiInputBase';
import { MuiInputLabel } from './MuiInputLabel';
import { MuiMenu } from './MuiMenu';
import { MuiModal } from './MuiModal';
import { MuiPaginationItem } from './MuiPaginationItem';
import { MuiPaper } from './MuiPaper';
import { MuiSelect } from './MuiSelect';
import { MuiSlider } from './MuiSlider';
import { MuiTabs } from './MuiTabs';
import { MuiTextField } from './MuiTextField';
import { MuiToggleButton } from './MuiToggleButton';
import { MuiToggleButtonGroup } from './MuiToggleButtonGroup';
import { MuiToolbar } from './MuiToolbar';
import { MuiTooltip } from './MuiTooltip';
import { MuiTypography } from './MuiTypography';
import { MuiPopover } from './MuiPopover';

/**
 * Темки для компонентов.
 * (сортируйте, пожалуйста, названия)
 */
export const components: Components = {
  MuiAppBar,
  MuiAutocomplete,
  MuiBackdrop,
  MuiBadge,
  MuiButton,
  MuiCardContent,
  MuiCheckbox,
  MuiChip,
  MuiDialog,
  MuiDialogActions,
  MuiDialogContent,
  MuiDialogTitle,
  MuiDivider,
  MuiFormHelperText,
  MuiIconButton,
  MuiInputAdornment,
  MuiInputBase,
  MuiInputLabel,
  MuiMenu,
  MuiModal,
  MuiPaginationItem,
  MuiPaper,
  MuiPopover,
  MuiSelect,
  MuiSlider,
  MuiTabs,
  MuiTextField,
  MuiToggleButton,
  MuiToggleButtonGroup,
  MuiToolbar,
  MuiTooltip,
  MuiTypography,
};
