import { DateTime } from 'luxon';
import { RangeSliding } from 'shared/types';
import { getQuarterEnd } from './getQuarterEnd';
import { getQuarterEndByDate } from './getQuarterEndByDate';
import { defined } from '../checks';
import { getWeekEndOffset } from './getWeekEndOffset';

/**
 * Функция расчёта даты окончания периода в привязке к календарю.
 * @param {RangeSliding} value Объект периода.
 * @param {DateTime} date Опорная дата.
 * @returns {DateTime} Дата окончания периода в привязке к календарю.
 */
export function endDateToCalendar(value: RangeSliding, date: DateTime) {
  switch (value.range.range) {
    case 'year': {
      return date.set({
        day: 31,
        month: 12,
      });
    }
    case 'fullQuarter': {
      return getQuarterEnd(date, defined(value.range.value));
    }
    case 'quarter': {
      return getQuarterEndByDate(date);
    }
    case 'month': {
      return date.set({ day: date.daysInMonth });
    }
    case 'week': {
      return getWeekEndOffset(date, value.weekStart);
    }
    default: {
      return date;
    }
  }
}
