import { RangeStatic } from 'shared/types';
import { utcFromLocalDate } from './utcFromLocalDAte';
import { endPeriodDisplayedToInternal } from './endPeriodDisplayedDoInternal';
import { addDurationToDateMonth } from './addDrationToDateMonth';

/**
 * Получение статического диапазона дат по-умолчанию (Месяц).
 * @returns {RangeStatic} Cтатический диапазон по-умолчанию.
 */
export function getDefaultStaticRange(toInternalPeriod = true): RangeStatic {
  const now = utcFromLocalDate(new Date());

  return {
    start: addDurationToDateMonth(now, -1).toISOString(),
    end: toInternalPeriod ? endPeriodDisplayedToInternal(now).toISOString() : now.toISOString(),
    fixedYear: true,
    toCurrentDate: false,
  } as RangeStatic;
}
