export function defined<T>(obj: T | undefined | null): T {
  if (obj === undefined || obj === null) {
    const errorText = 'Object undefined.';
    // TODO: реализовать сбор удалённых логов
    // console.error(errorText);
    throw new Error(errorText);
  }

  return obj;
}

export function isDefined<T>(value: T): value is NonNullable<T> {
  if (typeof value === 'string') {
    return value.length > 0;
  }
  return value !== undefined && value !== null;
}
